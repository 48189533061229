import React, { useState } from "react"
import LocalizedLink from "../localizedLink";
import Styles from "./SoftwareDownloadButton.module.scss"
import useTranslations from "../useTranslations";

const SoftwareDownloadButton = (props) => {
    const {agreeAndDownload, disagreeAndNotDownload, Downloading} = useTranslations()

    const [isLoading, setIsLoading] = useState({ sixFour: false, threeTwo: false });

    const downloadUrl = (yourKey, tmpKey, buttonKey) => {
        setIsLoading(prevState => ({ ...prevState, [buttonKey]: true }));
        fetch(`https://23jvp9yccg.execute-api.ap-northeast-1.amazonaws.com/prod/s3v2?your-key=${yourKey}&tmp-key=${tmpKey}`)
            .then((data) => {
                window.location.href = data.headers.get('location')
                }
            ).catch(() => {
                alert('ダウンロードに失敗しました。')
        }).finally(() => {
            setIsLoading(prevState => ({ ...prevState, [buttonKey]: false }));
        })
    }
        return (
            <div className={`${Styles.catalogDownloadButton} flex column`}>
                <div className={`${Styles.catalogDownloadAgree} flex row-to-column`}>
                    {props.yourKey != null &&
                        (isLoading.sixFour ?
                                <button className={Styles.catalogDownloadLoading} disabled={true}>
                                    {Downloading}
                                </button>
                                :
                                <button className="catalog-download-agree-64bit" onClick={() => {
                                    downloadUrl(props.yourKey, props.tmpKey, 'sixFour')
                                }}>
                                    {agreeAndDownload}
                                </button>
                        )
                    }
                    {props.yourKey64bit != null &&
                        (isLoading.sixFour ?
                                <button className={Styles.catalogDownloadLoading} disabled={true}>
                                    {Downloading}
                                </button>
                                :
                                <button className="catalog-download-agree-64bit" onClick={() =>{downloadUrl(props.yourKey64bit, props.tmpKey64bit, 'sixFour')}}>
                                    {agreeAndDownload}(64bit)
                                </button>
                        )
                    }
                    {props.yourKey32bit != null &&
                        (isLoading.threeTwo ?
                                <button className={Styles.catalogDownloadLoading} disabled={true}>
                                    {Downloading}
                                </button>
                                :
                                <button className="catalog-download-agree-32bit" onClick={() => {downloadUrl(props.yourKey32bit, props.tmpKey32bit, 'threeTwo')}}>
                                    {agreeAndDownload}(32bit)
                                </button>
                        )
                    }
                </div>
                <div className={Styles.catalogDownloadDisagree}>
                    <LocalizedLink to={`/support/software-download/`}>{disagreeAndNotDownload}</LocalizedLink>
                </div>
            </div>
        )
}

export default SoftwareDownloadButton;